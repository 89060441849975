/*
 * @Date: 2022-06-16 14:52:11
 * @LastEditors: pengff
 * @LastEditTime: 2022-06-24 15:07:34
 * @FilePath: \front-public\packages\work-platform\src\services\interceptors.ts
 */
import API from '@cloudpivot-shared/domain-api';
import { getLogoutPageConfig } from '@WorkPlatform/common/logoutConfig';
import { removeStorage } from '@WorkPlatform/common/utils';

API.Global.addInterceptor(
  'responseError',
  async err => {
    const getErrorStatus = (error: any) =>
      error.httpStatus || (error.response && error.response.status);
    const status: any = getErrorStatus(err);

    if (status === 401) {
      removeStorage();

      const address: string = await getLogoutPageConfig();
      if (address) {
        window.location.href = address;
      } else {
        const url: string = '/platform/#/login';
        const href: string = window.location.href;

        const isLogin: boolean = href.includes('/login');
        const wait: number = 1000;

        if (!isLogin) {
          let redirectUri: string = window.location.href;
          redirectUri = redirectUri.replace('#', 'hashsymbol');
          window.location.href = `${url}?redirect_url=${redirectUri}`;
          setTimeout(() => {
            location.reload();
          }, wait);
        }
      }

      return true;
    } else if (status === 403) {
      return true;
    }
    return false;
  },
  true,
);

// API的请求接口拦截器
API.Global.addInterceptor(
  'request',
  config => {
    // 忽略添加token的地址
    const ignoredTokenURLs = [
      '/api/sm4/security/get/switch/config',
    ];
    const hasIgnored = ignoredTokenURLs.some(i => {
      return config.url.indexOf(i) !== -1;
    });
    if (hasIgnored && config.url.indexOf('logout') < 0) {
      if (config.headers.Authorization) {
        delete config.headers.Authorization;
      }
      return true;
    }

    const appEntrance = localStorage.getItem('appEntrance') || 'platform';
    if (appEntrance) {
      config.headers['app-entrance'] = appEntrance;
    }

    // 其他地址查找localStorag 的token,自动附带到请求头上
    let token = localStorage.getItem('token');

    if (!token && config.headers.ruleToken) {
      token = config.headers.ruleToken;

      delete config.headers.ruleToken;
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return true;
  },
  false,
);
