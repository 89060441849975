/*
 * @Date: 2022-02-16 16:29:30
 * @LastEditors: pengff
 * @LastEditTime: 2022-06-24 12:07:42
 * @FilePath: \front-public\packages\work-platform\src\store\modules\work-platform.ts
 */
const workPlatform = {
  namespaced: true,
  state: {
    onlineApps: [],
    recentIds: [],
    recentApps: [],
    fromLocal: false,
    storageKey: null,
  },
  mutations: {
    updateFromLocal (state: any, append: any) {
      state.fromLocal = append;
    },
    updateOnlineApps (state: any, append: any) {
      state.onlineApps = append;
    },
    updateRecentIds (state: any, append: any) {
      if (append && Array.isArray(append)) {
        const key = state.storageKey;
        localStorage.setItem(key, JSON.stringify(append));
      }
      state.recentIds = append;
      console.log('state.recentIds', state.recentIds);
    },
    updateStorageKey (state: any) {
      if (!state.storageKey) {
        let publicUserInfo: any = localStorage.getItem('publicUserInfo');
        if (
          publicUserInfo &&
          typeof publicUserInfo === 'string' &&
          publicUserInfo !== 'undefined'
        ) {
          publicUserInfo = JSON.parse(publicUserInfo) || {};
          const userInfo: any = publicUserInfo || {};
          const userName = userInfo.name;
          const key = `RECENT_APP_IDS--${userName}`;
          state.storageKey = key;
        }
      }
    },
    updateRecentApps (state: any) {
      const { onlineApps, recentIds } = state;
      const apps: any[] = [];
      if (onlineApps.length && recentIds.length) {
        onlineApps.forEach((item: any) => {
          const order = recentIds.indexOf(item.id);
          if (order > -1) {
            apps.push({ ...item, order });
          }
        });
        apps.sort((a: any, b: any) => a.order - b.order);
        state.recentApps = apps;
      }
    },
    resetWorkPlatform (state: any) {
      state.onlineApps = [];
      state.recentIds = [];
      state.recentApps = [];
      state.fromLocal = false;
      state.storageKey = null;
      state.isSetOrg = false;
    },
  },
  actions: {
    setOlineApps ({ commit, state }: any, payload: any) {
      commit('updateOnlineApps', payload);
      if (!state.fromLocal) {
        commit('updateStorageKey');
        const key = state.storageKey;
        const recentAppStr = localStorage.getItem(key);
        if (typeof recentAppStr === 'string') {
          const recentApps = JSON.parse(recentAppStr);
          commit('updateRecentIds', recentApps);
        }
        commit('updateFromLocal', true);
      }
      commit('updateRecentApps');
    },
    setRecentIds ({ commit }: any, payload: any) {
      commit('updateStorageKey');
      commit('updateRecentIds', payload);
      commit('updateRecentApps');
    },
  },
  getters: {},
};

export default workPlatform;
