import API from '@cloudpivot-shared/domain-api';

export class FlowService {
  static getFlowInstanceList (params: any) {
    return API.Global.request({
      url: '/api/todo/workflow/search',
      method: 'get',
      params,
    });
  }

  /**
   * 获取流程模板
   */
  static async getWorkflowTree (params?: any) {
    return API.Global.request({
      url: '/api/todo/workflow/work_flows',
      method: 'get',
      params,
    });
  }

  static async getFlowEntrustList (params: any) {
    return API.Global.request({
      url: '/api/todo/workflow/agency_entrust',
      method: 'get',
      params,
    });
  }

  static async addEntrust (params: any) {
    return API.Global.request({
      url: '/api/todo/workflow/agency_entrust',
      method: 'post',
      data: params,
    });
  }

  static async updateEntrust (params: any) {
    return API.Global.request({
      url: '/api/todo/workflow/agency_entrust',
      method: 'put',
      data: params,
    });
  }

  static async delEntrust (params: any) {
    return API.Global.request({
      url: '/api/todo/workflow/agency_entrust',
      method: 'delete',
      data: params,
    });
  }

  static async getAppList (params: any) {
    return API.Global.request({
      url: '/api/todo/workflow/agency_entrust/apps',
      method: 'get',
      params,
    });
  }

  static async getGroupList (params: any) {
    return API.Global.request({
      url: `/api/metadata/app/${params.appCode}group`,
      method: 'get',
    });
  }

  static async getFlowList (params: any) {
    return API.Global.request({
      url: '/api/metadata/runtime/workflow/list',
      method: 'get',
      params,
    });
  }

  static async getPageJson (params: any) {
    return API.Global.request({
      url: `/api/launcher/${params.appCode}/application/workflow/loadContext`,
      method: 'post',
      data: params,
    });
  }

  static async getExceptionList (params: any) {
    return API.Global.request({
      url: '/api/todo/workflow/exception/list',
      method: 'get',
      params,
    });
  }

  static async getExceptionDetail (params: any) {
    return API.Global.request({
      url: `/api/todo/workflow/exception/${params.workflowInstanceId}`,
      method: 'get',
    });
  }

  static async fixExpLog (params: any) {
    return API.Global.request({
      url: `/api/launcher/${params.appCode}/application/instance/fix_exp_log`,
      method: 'post',
      data: params,
    });
  }
}
