/*
 * @Date: 2022-01-17 14:20:47
 * @LastEditors: pengff
 * @LastEditTime: 2022-06-24 12:07:37
 * @FilePath: \front-public\packages\work-platform\src\services\work-platform.ts
 */
import API from '@cloudpivot-shared/domain-api';

export class WorkPlatformService {
  /**
   *  添加常用应用配置
   */
  static addUsedApp (data: any) {
    return API.Global.request({
      url: '/api/todo/app/used/config',
      method: 'post',
      data,
    });
  }

  /**
   *  常用应用列表
   */
  static usedAppList () {
    return API.Global.request({
      url: '/api/todo/app/used/config/list',
      method: 'get',
    });
  }

  /**
   *
   * 获取待办列表
   */
  static getTodoList (params: any) {
    return API.Global.request({
      url: '/api/todo/todo/search',
      method: 'get',
      params,
    });
  }

  /**
   * 获取应用列表
   */
  static getAppList (params: any) {
    return API.Global.request({
      url: '/api/todo/app/deploy/search',
      method: 'get',
      params,
    });
  }

  /**
   * 获取流程|应用链接
   */
  static getAppUrl (params: any) {
    return API.Global.request({
      url: '/api/todo/app/deploy/get_app_url',
      method: 'get',
      params,
    });
  }

  /**
   * 获取组织列表
   */
  static getOrgList () {
    return API.Global.request({
      url: '/api/org/user/list_user_multiorg',
      method: 'get',
    });
  }

  /**
   * 设置组织
   */
  static setOrg (data: any) {
    return API.Global.request({
      url: `/api/org/user/choose_multiorg?corpId=${data}`,
      method: 'post',
    });
  }

  /**
   * 获取待办数量
   */
  static getWorkItemCount () {
    return API.Global.request({
      url: '/api/commonservice/todo/count_workitem',
      method: 'get',
    });
  }

  /**
   *根据id查询用户
  */
  static getUserDetail (id:string): Promise<any> {
    return API.Global.request({
      url: `/api/commonservice/organization/user/${id}`,
      method: 'get',
    });
  }
}
