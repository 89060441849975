/*
 * @Date: 2022-02-16 16:29:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-01 15:38:11
 * @FilePath: \front-public\packages\work-platform\src\services\login-service.ts
 */
import API from '@cloudpivot-shared/domain-api';
const prefix: string = '/api/authorization';

export class LoginService {
  static getKey () {
    return API.Global.request({
      url: `${prefix}/login/auth/get_rsa_key`,
      method: 'get',
    });
  }

  /**
     *获取平台权限
     * @returns
     */
  static getAllPerms () {
    return API.Global.request({
      url: '/api/commonservice/platform/role/perms',
      method: 'get',
    });
  }

  static login (params: any) {
    return API.Global.request({
      url: `${prefix}/login/password/user`,
      method: 'post',
      data: params,
    });
  }

  /**
     * 获取数据源
     */
  static getDataSource (params: any = {}) {
    return API.Global.request({
      url: '/api/commonservice/organization/department/get_datasource',
      method: 'get',
      params,
    });
  }

  static logout (params?: any) {
    return API.Global.request({
      url: `${prefix}/logout`,
      method: 'post',
      data: params,
    });
  }

  /**
   * 获取工作台的组织列表
  */
  static getOrgList () {
    return API.Global.request({
      url: '/api/commonservice/organization/config/list_by_baseuserid',
      method: 'get',
    });
  }

  /**
   * 获取工作台切换组织
  */
  static setOrg (params: any) {
    return API.Global.request({
      url: '/api/commonservice/organization/user/choose_organization',
      method: 'post',
      params,
    });
  }

  /**
   * 获取用户信息
   */
  static getLoginInfo () {
    return API.Global.request({
      url: '/api/commonservice/organization/user/get_login_info',
      method: 'get',
    });
  }

  /**
   * 未登录时获取登出页配置
   */
  static async getLogoutPageConfig () {
    const res: any = await API.Global.request({
      url: '/api/webapi/login/auth/get_login_url',
      method: 'get',
    });
    return res;
  }

  /**
   * 获取自动刷新token开关状态
   */
  static async getRefreshTokenSwitch () {
    const res: any = await API.Global.request({
      url: '/api/authorization/login/auth/refresh_token/switch',
      method: 'get',
    });
    return res;
  }
}
