export default [
  {
    path: '/m-work-platform',
    meta: { isWorkPlatform: true, keepAlive: false },
    component: () => import('@WorkPlatform/views/m-work-platform/index.vue'),
    children: [
      {
        path: '/',
        redirect: { name: 'm-work-platform-home' },
      },
      {
        path: '/m-work-platform-login',
        name: 'm-work-platform-login',
        component: () => import('@WorkPlatform/views/m-work-platform/setting/login.vue'),
        meta: {
          noShowHeader: true,
          showTabBar: false,
          isWorkPlatform: true,
        },
      },
      {
        path: '/m-work-platform-home',
        name: 'm-work-platform-home',
        component: () => import('@WorkPlatform/views/m-work-platform/home/index.vue'),
        meta: {
          noShowHeader: true,
          showTabBar: true,
          isWorkPlatform: true,
          keepAlive: false,
        },
      },
      {
        path: '/m-work-platform-home-search-app',
        name: 'm-work-platform-home-search-app',
        component: () => import('@WorkPlatform/views/m-work-platform/home/searchApp.vue'),
        meta: {
          noShowHeader: true,
          showTabBar: false,
          isWorkPlatform: true,
        },
      },
      {
        path: '/m-work-platform-notices',
        name: 'm-work-platform-notices',
        component: () => import('@WorkPlatform/views/m-work-platform/notices/index.vue'),
        meta: {
          noShowHeader: true,
          showTabBar: true,
          isWorkPlatform: true,
        },
      },
      {
        path: '/m-work-platform-setting',
        name: 'm-work-platform-setting',
        component: () => import('@WorkPlatform/views/m-work-platform/setting/index.vue'),
        meta: {
          noShowHeader: true,
          showTabBar: true,
          isWorkPlatform: true,
        },
      },
      {
        path: '/m-work-platform-change-password',
        name: 'm-work-platform-change-password',
        component: () =>
          import('@WorkPlatform/views/m-work-platform/setting/change-password.vue'),
        meta: {
          noShowHeader: true,
          showTabBar: false,
          isWorkPlatform: true,
        },
      },
    ],
  },
];
