export default [
  {
    path: '/work-platform',
    meta: { isWorkPlatform: true, keepAlive: false },
    component: () => import('@/views/work-platform/index.vue'),
    children: [
      {
        path: '/',
        redirect: { name: 'work-platform-home' },
      },
      {
        path: '/work-platform-home',
        name: 'work-platform-home',
        component: () => import('@/views/work-platform/work-platform.vue'),
        meta: {
          isWorkPlatform: true,
          keepAlive: false,
        },
      },
      {
        path: '/flow-center',
        name: 'flow-center',
        component: () => import('@/views/work-platform/flow-center/index.vue'),
        meta: {
          isWorkPlatform: true,
        },
      },
    ],
  },
];
