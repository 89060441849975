/*
 * @Author: xiaot
 * @Date: 2022-01-10 15:57:14
 * @Description:
 * @FilePath: \front-public\packages\work-platform\src\common\utils.ts
 */
import cookie from 'js-cookie';

/**
 * 清空storage
 * @param store
 */
export function removeStorage () {
  // 工作台
  cookie.remove('queryMsg');
  cookie.remove('expireTime');
  localStorage.removeItem('expireTime');
  document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  localStorage.removeItem('token');
  localStorage.removeItem('corpInfo');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('user');
  localStorage.removeItem('appCode');
  localStorage.removeItem('locale');
  localStorage.removeItem('perm');
  localStorage.removeItem('IS_FREELOGIN');

  // 开发平台
  localStorage.removeItem('debug_request_id');
  localStorage.removeItem('showStyleA');
  localStorage.removeItem('showStyleP');
  localStorage.removeItem('sortTypeA');
  localStorage.removeItem('sortTypeP');
  localStorage.removeItem('assetSSO');

  const storage: string[] = Object.keys(localStorage);
  storage.forEach(item => {
    if (item.indexOf('RECENT_APP_IDS') > -1) {
      localStorage.removeItem(item);
    }
  });
}

export function getTokenExpires (token:string) {
  if (!token) { return null; }
  const strings = token.split('.'); // 截取token，获取载体
  // 解析，需要把‘_’,'-'进行转换否则会无法解析
  const info = JSON.parse(decodeURIComponent(encodeURI(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/')))));
  console.log(new Date(info.exp * 1000));
  const expires = new Date(info.exp * 1000).toUTCString();
  return expires;
}

/**
 * 去掉属性字符串前后空格
 * @param originalData
 */
export function trim (originalData: any, type: string = 'trimStrFrontEnd') {
  forEachObject(originalData, (data: any, key: string, value: any) => {
    if (typeof value === 'string') {
      let method: any = () => { };

      if (type === 'trimStr') {
        method = trimStr;
      } else if (type === 'trimStrFrontEnd') {
        method = trimStrFrontEnd;
      } else if (type === 'trimStrFront') {
        method = trimStrFront;
      } else if (type === 'trimStrEnd') {
        method = trimStrEnd;
      }

      const replaceValue: string = method(value);
      data[key] = replaceValue;
    }
  });
}

/**
 * 递归遍历对象
 * @param data
 * @param callback
 * @returns
 */
export function forEachObject (data: any, callback: Function) {
  if (!data) return data;

  const keys: string[] = Object.keys(data);

  for (let i = 0, len = keys.length; i < len; i++) {
    const key: string = keys[i];
    const value: any = data[key];

    if (value instanceof Object || value instanceof Array) {
      forEachObject(value, callback);
    } else {
      callback(data, key, value);
    }
  }
  return false;
}

/**
 * 去除字符串前后空格
 * @param v
 * @returns
 */
export function trimStrFrontEnd (v: string) {
  return v.replace(/^(?:(\s)|(\s))$/, '');
}

/**
 * 去除字符串前空格
 * @param v
 * @returns
 */
export function trimStrFront (v: string) {
  return v.replace(/^\s/, '');
}

/**
 * 去除字符串前空格
 * @param v
 * @returns
 */
export function trimStrEnd (v: string) {
  return v.replace(/\s$/, '');
}

/**
 * 去除字符串所有空格
 * @param v
 * @returns
 */
export function trimStr (v: string) {
  return v.replace(/\s/, '');
}

/**
 * 从refs中获取指定的组件
 * @param refs
 * @param name
 * @returns
 */
export const getComponentByRefs = (refs: any, name: string) => {
  return new Promise((resolve: (value: unknown) => void) => {
    const id: number = setInterval(() => {
      if (refs[name]) {
        clearInterval(id);

        resolve(refs[name]);
      }
    });
  });
};

/**
 * 判断是否是PC
 */
export function isPC () {
  const userAgentInfo: string = navigator.userAgent;
  const Agents: string[] = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag: boolean = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) >= 0) {
      flag = false;
      break;
    }
  }

  return flag;
}

/**
 * 判断是否是移动端
 */
export function isPhone () {
  // eslint-disable-next-line max-len
  const reg: RegExp = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;

  const mobile = navigator.userAgent.match(reg);
  return mobile != null;
}

/**
 * 睡眠
 * @param time
 * @returns
 */
export function sleep (time: number) {
  return new Promise((resolve: (value: unknown) => void) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
}

export const getImageUrl = (img) => {
  const refUrl = '/api/webapi/file/common/download?refId=';
  return img ? (
    img.includes(refUrl) ? `${img}&accessToken=${localStorage.getItem('token')}` : img
  ) : '';
};
