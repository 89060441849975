/*
 * @Date: 2022-06-16 14:52:11
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-04 15:12:12
 * @FilePath: /front-public/packages/work-platform/src/router/index.ts
 */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { isPC, isPhone } from '@WorkPlatform/common/utils';
import mWorkPlatform from './router-list/mWorkPlatform';
import workPlatform from './router-list/workPlatform';
import { getLogoutPageConfig } from '@WorkPlatform/common/logoutConfig';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  ...workPlatform,
  ...mWorkPlatform,
  {
    path: '/login',
    name: 'login',
    component: () => import('@WorkPlatform/views/login/index.vue'),
  },
  {
    path: '/set-enterprise',
    name: 'setEnterprise',
    component: () => import('@WorkPlatform/views/login/set-enterprise.vue'),
  },
  {
    path: '*',
    redirect: { name: 'work-platform-home' },
  },
];

// 初始化路由
const router: VueRouter = new VueRouter({
  mode: 'hash',
  routes,
  base: 'platform',
});

// 守卫导航抛出错误时的处理回调函数
router.onError((error: any) => {
  const pattern = /Loading chunk [a-zA-Z0-9_-]+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);

  if (isChunkLoadFailed) {
    Vue.prototype.$errorcatcher.showMessagePopup({
      title: '错误提示！',
      message: ['Loading chunk xxx failed', '路由懒加载找不到对应的moudle'],
      correctGuide: '系统升级更新，请通过F5刷新页面获取最新资源',
      autoHide: true,
      onClose: () => window.location.reload(),
    });
  }
});

router.beforeEach(async (to: any, from: any, next: any) => {
  const token: any = localStorage.getItem('token');
  if (!token) {
    document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    const address: string = await getLogoutPageConfig();
    if (to.name !== 'login' && address) {
      window.location.href = address;
      return;
    }
    if (isPhone() && to.name !== 'm-work-platform-login' && to.name !== 'm-work-platform-change-password') {
      next('/m-work-platform-login');
    } else if (isPC() && to.name !== 'login') {
      let redirectUri: string = window.location.href;
      redirectUri = redirectUri.replace('#', 'hashsymbol');
      next(`/login?redirect_url=${redirectUri}`);
    } else {
      next();
    }
    return;
  }

  if (isPhone() && to.name === 'work-platform-home') {
    next('/m-work-platform');
    return;
  } else if (isPC() && to.name === 'm-work-platform-home') {
    next('/work-platform-home');
    return;
  }

  // 如果是登录页面
  if (to.name === 'm-work-platform-login') {
    next('/m-work-platform');
    return;
  } else if (to.name === 'login') {
    next('/');
    return;
  }

  next();
});

export default router;
