import { LoginService } from '@WorkPlatform/services';

const Global = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async getAllPerms () {
      const token: any = localStorage.getItem('token');
      if (!token) return;
      const res: any = await LoginService.getAllPerms();

      if (res.success) {
        const data: any = res.data || {};
        const perm: any = {
          isAllAppPerm: data.allAppPerm,
          permList: data.resourceList,
          appPermList: data.appPerm,
        };

        localStorage.setItem('perm', JSON.stringify(perm));
      }
    },
  },
  getters: {},
};

export default Global;
