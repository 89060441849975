import Vue from 'vue';
import Vuex from 'vuex';
import workPlatform from './modules/work-platform';
import Global from './modules/global';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    workPlatform,
    Global,
  },
});
