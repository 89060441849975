









import { Component, Vue } from 'vue-property-decorator';
import { ConfigProvider } from '@h3/antd-vue';
import zhCN from '@h3/antd-vue/lib/locale-provider/zh_CN';

@Component({
  name: 'App',
  components: {
    AConfigProvider: ConfigProvider,
  },
})
export default class App extends Vue {
  locale = zhCN;

  get isMobile () {
    return /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent);
  }
}
